import { makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
import * as React from 'react'
import { ReactElement } from 'react'
import Layout from '../../components/Layout'
import { IProject, ProjectsComponent } from '../../components/ProjectsComponent'

export const query = graphql`
  query ENProjectsQuery {
    allContentXlsxEnProjects {
      nodes {
        name
        acronym
        time
        financingInstitution
        programme
        number_
        job
        page
      }
    }
  }
`
const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '1.5em',
    marginLeft: '10px'
  }
})

interface Nodes {
  nodes: Array<IProject>
}

interface DataSheet {
  allContentXlsxEnProjects: Nodes
}

interface Props {
  data: DataSheet
}

interface Props {
  data: DataSheet
}

const Projects = ({
  data: {
    allContentXlsxEnProjects: { nodes: projects }
  }
}: Props): ReactElement => {
  const styles = useStyles()

  return (
    <Layout actualTab="Projects" isEn>
      <>
        <p className={styles.title}>Projects</p>
        <ProjectsComponent projects={projects} isEn/>
      </>
    </Layout>
  )
}

export default Projects
